<template>
    <h4 class="label py-1 mb-1 border-bottom">
        <slot>{{ label }}</slot>
        <button class="btn btn-xs button py-0" @click="reset">
            <slot name="button"></slot>
        </button>
    </h4>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        }
    },
    name: "FilterLabel",
    methods: {
        reset() {
            this.$emit('reset');
        }
    }
}
</script>

<style scoped>
h4.label {
    font-weight: 200;
    font-size: 1.025rem;
}

h4 .button {
    float: right;
    font-size: 0.6rem;
    position: relative;
    right: -10px;
}
</style>
