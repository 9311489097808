<template>
  <div>
    <b-navbar fixed="top" class="navbar-filters overflow-x-scroll" :variant="navbarVariant">
      <b-navbar-nav>
        <b-nav-item @click="filtersSidebar = true" class="text-nowrap">{{ $t('titles.filters') }} <feather-icon icon="ArrowDownIcon" size="11"></feather-icon></b-nav-item>
        <b-nav-item @click="activeFilter = 'categories'" class="text-nowrap">{{ $t('labels.categories') }} <feather-icon icon="ArrowDownIcon" size="11"></feather-icon></b-nav-item>
        <b-nav-item @click="activeFilter = 'price'" class="text-nowrap">{{ $t('label.price') }} <feather-icon icon="ArrowDownIcon" size="11"></feather-icon></b-nav-item>
        <b-nav-item v-for="attribute in attributes" :key="attribute.key" class="text-nowrap" @click="activeFilter = attribute.key">{{_.get(attributesConstants, attribute.key, attribute.key)}} <feather-icon icon="ArrowDownIcon" size="11"></feather-icon></b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-card class="mobile-filters-container w-100" :class="{'open': filtersSidebar}" no-body no-shadow>
      <b-card-header class="d-flex justify-content-between p-1" v-if="activeFilter === null">
        <h2>{{ $t('titles.filters') }}</h2>
        <b-button-close @click="filtersSidebar = false"></b-button-close>
      </b-card-header>
      <search-filters :loading="loading" mobile :activeFilter="activeFilter"></search-filters>
      <b-card-footer class="p-1">
        <b-button class="btn-block" variant="primary" @click="filtersSidebar = false">{{ $t('buttons.see_results') }}</b-button>
      </b-card-footer>
    </b-card>
    <div class="modal-backdrop" v-if="filtersSidebar" @click="filtersSidebar = false"></div>
  </div>
</template>

<script>
import {BNavbar, BNavItem, BNavbarNav, BButton, BCard, BCardFooter, BCardHeader, BButtonClose} from "bootstrap-vue";
import SearchFilters from "@/views/pricex/elements/Search/SearchFilters";

export default {
  components: {
    SearchFilters,
     BNavbar, BNavItem, BNavbarNav, BButton, BCard, BCardFooter, BCardHeader, BButtonClose
  },
  name: "MobileFilters",
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      filtersSidebar: false,
      activeFilter: null
    }
  },
  computed: {
    attributes() {
      return this.$store.state.search.attributes;
    },
    attributesConstants() {
      return this.$store.state.attributes.items;
    },
    skin(){
      return this.$store.state.appConfig.layout.skin;
    },
    navbarVariant(){
      return this.skin;
    }
  },
  watch: {
    filtersSidebar(val){
      let bodyElement = document.getElementsByTagName('body')[0]
      if(val === true){
        bodyElement.classList.add('modal-open');
      }else{
        bodyElement.classList.remove('modal-open');
        this.activeFilter = null
      }
    },
    activeFilter(val){
      if(val !== null){
        this.filtersSidebar = true
      }
    }
  }
}
</script>
<style scoped>
.navbar-filters {
  margin-top: 66px;
  z-index: 100;
}
</style>