<template>
  <div class="pt-4">
    <b-alert :show="!products.length && !loading" variant="info" class="p-4 mx-auto col-md-6">
      {{ $t('messages.there_is_no_products_found_for_selected_criteria') }}
    </b-alert>
    <search-filters
        v-if="['lg', 'xl'].indexOf(breakpoint) > -1"
        v-show="products.length && showFilters"
        :loading="loading"
    ></search-filters>
    <div class="search-results mt-5 mt-md-2">
      <mobile-filters v-show="products.length" :loading="loading"
                      v-if="['xs', 'sm', 'md'].indexOf(breakpoint) > -1"></mobile-filters>
      <products-grid :pageLoading="loadingPage" :loading="loading" :products="products"
                     :key="gridIndex"></products-grid>
      <div v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"></div>
    </div>
    <b-overlay :show="loading" no-center opacity="0.9" no-wrap>
      <template #overlay="">
        <div class="position-fixed products-overlay-spinner text-center w-100">
          <b-spinner class="mr-1" small type="grow" variant="primary"></b-spinner>
          {{ $t('messages.loading_data') }}
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ProductsGrid from "@/views/pricex/elements/Products/ProductsGrid";
import SearchFilters from "@/views/pricex/elements/Search/SearchFilters";
import {mapActions} from "vuex"
import _ from "lodash"
import {BOverlay, BSpinner, BAlert} from "bootstrap-vue";
import MobileFilters from "@/views/pricex/elements/Filters/MobileFilters";
import SearchObject from "@/store/pricex/modules/SearchObject";

export default {
  name: "SearchComponent",
  components: {
    MobileFilters,
    SearchFilters,
    ProductsGrid,
    BOverlay, BSpinner, BAlert
  },
  metaInfo() {
    return {
      title: this.query || this.$t('page_titles.search'),
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.query || this.$t('page_titles.search') + ' | ' + 'PriceX'
        },
      ]
    }
  },
  computed: {
    breakpoint() {
      return this.$store.getters["app/currentBreakPoint"]
    },
    showFilters() {
      return ['lg', 'xl'].indexOf(this.breakpoint) > -1
    },
    categories() {
      return JSON.parse(JSON.stringify(this.$store.state.search.categories));
    },
    products() {
      return this.$store.state.search.items;
    },
    filters() {
      return this.$store.state.search.filters;
    },
    mappedCategories() {
      return this.$store.state.categories.mapped;
    },
    query() {
      let params = this.$route.query
      return _.get(params, 'q', null)
    }
  },
  data() {
    return {
      limit: 24,
      page: 0,
      gridIndex: 0,
      loading: false,
      loadingPage: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 200px 0px',
        thresholds: [1]
      },
    }
  },
  created() {
    this.loading = true

    this.filters.min_price = null
    this.filters.max_price = null
    this.setCategories([])
    this.updateSearch(this.query, this.page * this.limit, this.limit);
  },
  methods: {
    ...mapActions('search', ['search', 'setCategories']),
    updateSearch(query, from, size) {
      this.disableReload = true;
      let searchBody = new SearchObject(this.query)
      searchBody
          .setFrom(from)
          .setSize(size)
          .setAttributes(this.filters.attributes)
          .setCategories(this.filters.categories)
          .setCountries(['BG']) //TODO make it dynamic
      return this.search(searchBody)
          .then(result => {
            this.totalHits = result.hits.total.value;
            setTimeout(() => {
              this.disableReload = false;
            }, 1000);
          })
          .finally(() => {
            this.loading = false
          });
    },
    onWaypoint: _.debounce(function ({going, direction}) {
      // going: in, out
      // direction: top, right, bottom, left
      if (going === this.$waypointMap.GOING_IN && direction === this.$waypointMap.DIRECTION_TOP && this.products.length) {
        this.page++;
        this.loadingPage = true
        this.updateSearch(this.query, this.page * this.limit, this.limit).finally(() => {
          this.loadingPage = false
        })
      }
    }, 300, {leading: true, trailing: false}),
    getUrlParam(key, def) {
      let params = this.$route.query
      return _.get(params, key, def)
    },
  },
  watch: {
    filters: {
      handler: function () {
        if (this.disableReload) {
          return;
        }
        this.page = 0;
        this.loading = true;
        this.disableReload = true
        this.updateSearch(this.query, 0, this.limit).finally(() => {
          this.loading = false;
          setTimeout(() => {
            this.disableReload = false
          }, 1000)
        });
      },
      deep: true
    },
    query: function (value) {
      this.setCategories([])
      this.filters.categories = []
      this.filters.min_price = null;
      this.filters.max_price = null;
      this.updateSearch(value, 0, this.limit)
    }
  }
}
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";


@include media-breakpoint-up(lg) {
  .search-results {
    margin-left: 290px;
    width: calc(100% - 290px);
  }
}

.results-title {
  font-size: 16px;
}

.products-overlay-spinner {
  top: 200px;
  font-size: 140%;
}

.results-categories-container {
  overflow-x: hidden;

  .results-categories {
    overflow-x: auto;
    border-radius: 10px;

    .btn {
      white-space: nowrap;
    }
  }
}
</style>
