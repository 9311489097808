<template>
  <div class="d-flex flex-column mb-3 characteristics-container">
    <filter-label :label="label" v-if="label" @reset="reset">
      <template v-slot:button v-if="canReset">
        <feather-icon icon="RefreshCcwIcon"></feather-icon>
      </template>
    </filter-label>
    <b-form-checkbox-group
        v-model="items"
        :options="options"
        :aria-label="label"
        text-field="label"
        stacked
    >
    </b-form-checkbox-group>
  </div>
</template>

<script>
// import Checkbox from "@/views/pricex/elements/Checkbox";
import FilterLabel from "@/views/pricex/elements/FilterLabel";
import {BFormCheckboxGroup} from "bootstrap-vue";
export default {
  components: {
    FilterLabel, //Checkbox,
    BFormCheckboxGroup
  },
  props: {
    label: {
      required: false,
      type: String
    },
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    },
    canReset: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.items = this.value;
  },
  methods: {
    reset() {
      this.items = [];
    }
  },
  watch: {
    items(newValue) {
      this.$emit('input', newValue);
    },
    value(newValue) {
      this.items = newValue;
    }
  },
  name: "CharacteristicsSelector"
}
</script>
<style scoped>
.characteristics-container {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}
.filter-checkbox {
  margin-bottom: 5px;
}
</style>
