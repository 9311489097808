import _ from 'lodash'

class SearchObject {
    constructor(query) {
        this.body = {
            from: 0,
            size: 0,
            track_scores: true,
            query: {
                bool: {
                    filter: {
                        bool: {
                            must_not: {
                                match: {
                                    is_active: false
                                }
                            },
                            must: []
                        }
                    }
                }
            },
            sort: [
                {_score: "desc"}
            ]
        }

        if (query) {
            this.body.query.bool.must = [
                {
                    multi_match: {
                        query: query,
                        fields: ['name^10', 'website^1', 'description^3', 'url^4'],
                        minimum_should_match: 2,
                    }
                }
            ];
            this.body.rescore = {
                window_size: 1000,
                query: {
                    score_mode: "multiply",
                    query_weight: 0.7,
                    rescore_query_weight: 1.2,
                    rescore_query: {
                        bool: {
                            should: [
                                {
                                    multi_match: {
                                        query: query,
                                        type: 'phrase',
                                        fields: ['name^10', 'website^1', 'description^3', 'url^4'],
                                        boost: 3
                                    }
                                },
                                {
                                    multi_match: {
                                        query: query,
                                        operator: 'and',
                                        fields: ['name^10', 'website^1', 'description^3', 'url^4'],
                                        boost: 2
                                    }
                                },
                            ]
                        }
                    },
                }
            }
        }else{
            this.body.sort.push({timestamp: "desc"})
        }
    }

    setCategories(categories) {
        if (!categories.length) {
            return this;
        }
        this.body.query.bool.filter.bool.must.push({
            terms: {
                categories: categories
            }
        })
        return this;
    }

    setCountries(countries) {
        if (!countries.length) {
            return this;
        }
        this.body.query.bool.filter.bool.must.push({
            terms: {
                countries: countries
            }
        })
        return this;
    }

    setAttributes(attributes) {
        if (!attributes.length) {
            return this;
        }
        _.set(this.body, 'query.bool.must', []);
        _.forEach(attributes, attribute => {
            this.body.query.bool.must.push({
                nested: {
                    path: 'attributes',
                    query: {
                        bool: {
                            must: [
                                {match: {'attributes.code': attribute.name}},
                                {match: {'attributes.value': attribute.value}}
                            ]
                        }
                    }
                }
            })
        })
        return this;
    }

    setFrom(from) {
        this.body.from = from;
        return this;
    }

    setSize(size) {
        this.body.size = size;
        return this;
    }

    setPriceRange(from, to) {
        this.body.query.bool.filter.bool.must.push({
            range: {
                price: {
                    gte: from,
                    lte: to
                }
            }
        })
        return this;
    }

    getAggregationQuery(size) {
        //let query = _.clone(this.body.query);
        return {
            // size: 0,
            // query: query,
            aggregations: {
                categories: {
                    terms: {
                        field: 'categories',
                        size: size,
                        order: {
                            _count: 'desc'
                        }
                    }
                },
                countries: {
                    terms: {
                        field: 'countries',
                        size: size,
                        order: {
                            _count: 'desc'
                        }
                    }
                },
                attributes: {
                    nested: {
                        path: 'attributes'
                    },
                    aggregations: {
                        items: {
                            terms: {
                                field: 'attributes.code'
                            },
                            aggregations: {
                                values: {
                                    terms: {
                                        field: 'attributes.value'
                                    }
                                }
                            }
                        }
                    }
                },
                max_price: {
                    max: {
                        field: 'price'
                    }
                },
                min_price: {
                    min: {
                        field: 'price'
                    }
                }
            }
        }
    }

    getBody() {
        return this.body;
    }
}

export default SearchObject;
