<template>
  <div class="sidebar-detached sidebar-left filters-container" :class="{'position-fixed': !mobile}">
    <div class="sidebar" v-if="['lg', 'xl'].indexOf(breakpoint) > -1">
      <div
          class="sidebar-shop">
        <b-card class="shadow-sm">
          <range-picker :min="min_price" :max="max_price" @input="v => priceRange = v"></range-picker>
          <characteristics-selector :label="$t('labels.categories')"
                                    canReset
                                    v-if="categories.length"
                                    v-model="filters.categories"
                                    :options="formatCategoryValues(categories)">
            <template v-slot:item.label="{label}">
              {{ _.get(mappedCategories, label, label) }}
            </template>
          </characteristics-selector>
          <characteristics-selector :label="_.get(attributesConstants, attribute.key, attribute.key)"
                                    canReset
                                    :value="_.get(filters.attributes, attribute.key)"
                                    v-for="attribute in attributes" :key="attribute.key"
                                    @input="v => updateAttributes(v, attribute.key)"
                                    :options="formatAttributeValues(attribute)">

          </characteristics-selector>
        </b-card>
      </div>
    </div>
    <div v-if="['xs', 'sm', 'md'].indexOf(breakpoint) > -1">
      <b-tabs vertical nav-wrapper-class="filter-tabs" class="mw-100 m-0"
              v-if="(attributes.length > 0 || categories.length > 0) && activeFilter === null">
        <b-tab>
          <template #title>
            {{ $t('labels.categories') }}
          </template>
          <characteristics-selector :label="$t('labels.categories')"
                                    canReset
                                    v-if="categories.length"
                                    v-model="filters.categories"
                                    :options="formatCategoryValues(categories)">
            <template v-slot:item.label="{label}">
              {{ _.get(mappedCategories, label, label) }}
            </template>
          </characteristics-selector>
        </b-tab>
<!--        <b-tab>-->
<!--          <template #title>-->
<!--            {{ $t('label.price') }}-->
<!--          </template>-->
<!--          <filter-label :label="$t('label.price')">-->
<!--            <template v-slot:button>-->
<!--              <feather-icon icon="RefreshCcwIcon" @click="priceRange = []"></feather-icon>-->
<!--            </template>-->
<!--          </filter-label>-->
<!--          <b-form-radio-group-->
<!--              v-model="priceRange"-->
<!--              :options="formatMobilePriceRange"-->
<!--              :aria-label="$t('filters.price_range')"-->
<!--              stacked-->
<!--          >-->
<!--          </b-form-radio-group>-->
<!--        </b-tab>-->
        <b-tab
            v-for="attribute in attributes" :key="attribute.key"
        >
          <template #title>
            <span v-if="_.get(filters, 'attributes.' + attribute.key, []).length"
                  class="text-danger mr-1">⌾️</span>{{ _.get(attributesConstants, attribute.key, attribute.key) }}
          </template>
          <characteristics-selector
              v-if="_.get(attribute, 'key')"
              :label="_.get(attributesConstants, attribute.key, attribute.key)"
              canReset
              :value="_.get(filters.attributes, attribute.key)"
              @input="v => updateAttributes(v, attribute.key)"
              :options="formatAttributeValues(attribute)">

          </characteristics-selector>
        </b-tab>
      </b-tabs>
      <div v-else class="px-1">
        <characteristics-selector :label="$t('labels.categories')"
                                  canReset
                                  v-if="categories.length && activeFilter === 'categories'"
                                  v-model="filters.categories"
                                  :options="formatCategoryValues(categories)">
          <template v-slot:item.label="{label}">
            {{ _.get(mappedCategories, label, label) }}
          </template>
        </characteristics-selector>
        <div v-if="activeFilter === 'price'">
          <filter-label :label="$t('label.price')">
            <template v-slot:button>
              <feather-icon icon="RefreshCcwIcon" @click="priceRange = []"></feather-icon>
            </template>
          </filter-label>
          <b-form-radio-group
              v-model="priceRange"
              :options="formatMobilePriceRange"
              :aria-label="$t('filters.price_range')"
              stacked
          >
          </b-form-radio-group>
        </div>
        <characteristics-selector
            v-if="_.get(_.first(attributes), 'key')"
            :label="_.get(attributesConstants, _.first(attributes).key, _.first(attributes).key)"
            canReset
            :value="_.get(filters.attributes, _.first(attributes).key)"
            @input="v => updateAttributes(v, _.first(attributes).key)"
            :options="formatAttributeValues(_.first(attributes))">

        </characteristics-selector>
      </div>
    </div>
  </div>
</template>

<script>
import {BCard, BFormRadioGroup, BTab, BTabs} from "bootstrap-vue";
import CharacteristicsSelector from "@/views/pricex/elements/Filters/CharacteristicsSelector";
import _ from "lodash"
import {mapActions} from "vuex";
import RangePicker from "@/views/pricex/elements/Filters/RangePicker";
import FilterLabel from "@/views/pricex/elements/FilterLabel";

export default {
  components: {
    FilterLabel,
    RangePicker,
    BCard,
    CharacteristicsSelector,
    BTab, BTabs, BFormRadioGroup
  },
  name: "SearchFilters",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    activeFilter: {
      default: null
    }
  },
  computed: {
    min_price() {
      return this.$store.state.search.min_price;
    },
    max_price() {
      return this.$store.state.search.max_price < 1000000 ?
          this.$store.state.search.max_price
          : 1000000;
    },
    attributes() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.state.search.attributes.filter(item => {
        return this.activeFilter === null || this.activeFilter === item.key
      }).sort(item => {
        if (_.get(this.filters.attributes, item.key) !== undefined) {
          return -1;
        }
        return 0;
      });
    },
    categories() {
      return this.$store.state.search.categories;
    },
    mappedCategories() {
      return this.$store.state.categories.mapped;
    },
    attributesConstants() {
      return this.$store.state.attributes.items;
    },
    routeQuery() {
      return this.$route.query
    },
    breakpoint() {
      return this.$store.getters["app/currentBreakPoint"]
    },
    formatMobilePriceRange() {
      let ranges = [
        [0, 10000],
        [10000, 19900],
        [20000, 29900],
        [30000, 39900],
        [40000, 49900],
        [50000, 49900],
        [60000, 69900],
        [70000, 79900],
        [80000, 89900],
        [90000, 99900],
        [100000, 109900],
        [110000, 119900],
        [120000, 129900],
        [130000, 139900],
        [140000, 149900],
        [150000, 159900],
        [160000, 169900],
        [170000, 179900],
        [180000, 189900],
        [190000, 200000],
      ]
      if (this.max_price < 200000) {
        ranges = ranges.filter(range => {
          return range[0] < this.max_price && range[1] > this.min_price
        })
      }

      return ranges.map(range => {
        return {value: range, text: this.$t('filters.price_range_checkbox', {min: range[0] / 100, max: range[1] / 100})}
      })
    }
  },
  data() {
    return {
      filters: {
        categories: [],
        min_price: null,
        max_price: null,
        attributes: {}
      },
      query: null,
      priceRange: [],
      categoryFilterIndex: 0
    }
  },
  created() {
    let query = this.getUrlParam('q');
    if (query) {
      this.query = query;
    }
    if (!_.isEmpty(this.$route.query)) {
      setTimeout(() => {
        for (const [key, value] of Object.entries(this.$route.query)) {
          if (['categories', 'min_price', 'max_price', 'q', 'min', 'max'].includes(key)) {
            continue
          }
          this.$set(this.filters.attributes, key, _.split(value, ','))
        }
      }, 1000)
    }
  },
  methods: {
    ...mapActions('search', ['setFilters']),
    formatAttributeValues(attribute) {
      let values = [];
      for (let i = 0; i < attribute.values.buckets.length; i++) {
        values.push({
          label: attribute.values.buckets[i].key + ' (' + attribute.values.buckets[i].doc_count + ')',
          value: attribute.values.buckets[i].key,
          count: attribute.values.buckets[i].doc_count
        });
      }

      return values;
    },
    formatCategoryValues(categories) {
      let values = [];
      for (let i = 0; i < categories.length; i++) {
        values.push({
          label: _.get(this.mappedCategories, categories[i].key, categories[i].key) + ' (' + categories[i].doc_count + ')',
          value: categories[i].key,
          count: categories[i].doc_count
        });
      }
      return values;
    },
    updateAttributes(attributeValues, attributeKey) {
      this.$set(this.filters.attributes, attributeKey, attributeValues);
    },
    getUrlParam(key, def) {
      let params = this.$route.query
      return _.get(params, key, def)
    },
  },
  watch: {
    filters: {
      handler: _.debounce(function () {
        this.setFilters(_.cloneDeep(this.filters));
      }, 500, {leading: false, trailing: true}),
      deep: true
    },
    priceRange: _.debounce(function (newValue) {
      this.filters.min_price = newValue[0];
      this.filters.max_price = newValue[1];
    }, 300, {leading: false, trailing: true}),
  }
}
</script>
<style scoped lang="scss">
.filters-container {
  height: 100%;
  overflow-y: auto;
  top: 66px;
}
</style>
