<template>
  <div class="range-picker mb-2">
    <filter-label :label="$t('filters.price_range')" @reset="resetValue">
      <template v-slot:button>
        <feather-icon icon="RefreshCcwIcon"></feather-icon>
      </template>
    </filter-label>
    <vue-slider v-model="value" :process="true" :min="0" :max="max"
                tooltipPlacement="bottom"
                :interval="1"
                hide-label
                tooltip="always"
                :contained="true"
    >
      <template v-slot:label="{ label, active }">
        <div :class="['vue-slider-mark-label', { active }]">
          <price from-cents :value="label" :fractionDigits="0" disable-currency v-if="label"></price>
        </div>
      </template>
      <template v-slot:tooltip="{ value, focus }">
        <div :class="[{ focus }]">
          <price from-cents :value="value" :fractionDigits="0" disable-currency v-if="value"></price>
        </div>
      </template>
    </vue-slider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import FilterLabel from "@/views/pricex/elements/FilterLabel";
import 'vue-slider-component/theme/antd.css'
import Price from "@/views/pricex/elements/Price";

export default {
  components: {Price, FilterLabel, VueSlider},
  name: "RangePicker",
  props: {
    min: {
      type: Number,
      required: false,
      default: 0
    },
    max: {
      type: Number,
      required: false,
      default: 1000000
    }
  },
  data() {
    return {
      value: [this.min, this.max]
    };
  },
  methods: {
    resetValue() {
      this.value = [0, 1000000]
    }
  },
  watch: {
    min() {
      this.value = [this.min, this.max];
    },
    value() {
      this.$emit('input', this.value);
    }
  }
}
</script>
